.translate-languages {
  ul {
    display: flex;
    justify-content: space-evenly;

    li {
      position: relative;
      display: flex;
      margin: var(--spacing-xs) 0;
      padding: var(--spacing-xs);
      height: 2.5rem;
      width: auto;
      box-sizing: content-box;
      cursor: pointer;
      border: 2px solid var(--color-grey);
      border-radius: var(--border-radius-sm);

      @media #{$screen-s} {
        height: 2rem;
      }

      &.current,
      &:hover {
        box-shadow: inset 0 0 4px var(--color-ocean);
        border: 2px solid var(--color-ocean)
      }

      &.active::after {
        content: '\2713\0020';
        position: absolute;
        top: -10px;
        right: -10px;
        font-size: $font-size-22;
        line-height: 1;
        color: var(--color-green);
      }
    }

    img {
      height: 100%;
      border: 1px solid var(--color-grey);
      border-radius: var(--border-radius-xs);
      box-sizing: content-box;
    }
  }
}
