.networks {
  display: flex;
  flex-wrap: wrap;

  @media #{$screen-s}, #{$screen-m} {
    justify-content: center;
  }

  .network-item {
    position: relative;
    text-align: center;
    width: 21rem;

    &.active::after {
      content: '\2713\0020';
      position: absolute;
      top: -10px;
      right: 10px;
      font-size: $font-size-36;
      line-height: 1;
      color: var(--color-green);
    }

    input {
      display: none;
    }

    input[type=checkbox]:checked + label {
      border: 2px solid var(--color-green);
    }

    .network-img {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      max-width: 100%;
      margin-bottom: 1rem;

      img, svg {
        max-height: 100%;
        max-width: 100%;
        user-select: none;
        cursor: pointer;
      }
    }

    label {
      display: block;
      margin: 0 1rem 1rem 0;
      padding: 2rem;
      justify-content: center;
      text-align: center;
      align-items: center;
      border: 2px solid var(--color-grey);
      border-radius: var(--border-radius-md);
      color: var(--color-midnight);
      cursor: pointer;

      .network-icon {
        margin: 0 5px;
      }
    }
  }
}

.partner {
  &-count {
    color: var(--color-green);
    font-size: $font-size-24;
    font-weight: $font-weight-bold;
    margin: var(--spacing-xs);
  }

  &__purpose {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);

    img {
      width: 1rem;
    }
  }
}
