.purposes-section {
  width: 100%;
  max-width: 55rem;

  & &__list {
    min-height: 20rem;
  }

  & &__item {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-xs);
    padding: var(--spacing-xs) var(--spacing-sm);
    width: fit-content;
    font-size: $font-size-14;
    font-weight: $font-weight-light;
    border-radius: var(--border-radius-md);
    color: var(--color-midnight);

    &--partner {
      background-color: var(--color-lavender);

      &--active {
        background-color: var(--color-lavender-dark);
      }

      &--blocked {
        background-color: var(--color-grey-light);
        color: var(--color-grey-dark);
      }
    }

    &--publisher {
      background-color: var(--color-sand);
    }

    &__legal-basis {
      display: block;
      font-style: italic;
      font-weight: $font-weight-semi-bold;
      margin-top: var(--spacing-xs);
      color: var(--color-rust);
    }

    i {
      font-size: inherit;
    }
  }
}

.purposes-display-section {
  & &__item {
    display: inline-block;
    text-align: center;
    width: 25rem;

    img {
      max-width: 100%;
      height: auto;
      cursor: pointer;
    }

    label {
      display: block;
      margin: 1rem;
      padding: var(--spacing-xl) var(--spacing-lg);
      justify-content: center;
      text-align: center;
      align-items: center;
      border: 1px solid var(--color-grey);
      border-radius: var(--border-radius-md);
      cursor: pointer;

      @media #{$screen-s}, #{$screen-m} {
        padding: var(--spacing-lg) var(--spacing-md);
      }
    }

    &__title {
      display: inline-flex;
      align-items: center;
      gap: var(--spacing-xs);
      color: var(--color-midnight-light);
      font-weight: $font-weight-semi-bold;
    }
  }
}
