$toolbar-button-spacing: 14px;
$toolbar-button-padding-small: 15px;
$toolbar-button-padding-medium: 25px;
$toolbar-button-box-shadow-color: #00000020;

.toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 100%;

  & &__content {
    display: block;
    position: relative;
    user-select: none;
  }

  & &__icon {
    cursor: pointer;

    svg {
      filter: drop-shadow(0px -5px 6px $toolbar-button-box-shadow-color);
    }

    &:not(.toolbar__icon--static) {
      svg:hover {
        transform: translateY(-5px);
      }
    }
  }

  & &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $toolbar-button-spacing;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    transition: color .1s ease-out, background .1s ease-out;
    border-radius: var(--border-radius);
    -webkit-box-shadow: 0 -5px 12px -2px $toolbar-button-box-shadow-color;
    -moz-box-shadow: 0 -5px 12px -2px $toolbar-button-box-shadow-color;
    box-shadow: 0 -5px 12px -2px $toolbar-button-box-shadow-color;
    background: var(--main-color);
    color: var(--background-color);
    border: 1px solid var(--background-color);
    padding: calc($toolbar-button-padding-medium / 2) $toolbar-button-padding-medium;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-size: var(--font-size) !important;
    line-height: 1.4;
    cursor: pointer;

    * {
      font-family: var(--font-family), Helvetica, Arial, sans-serif!important;
      white-space: nowrap;
    }

    &:not(.toolbar__button--static):hover {
      background: var(--background-color);
      color: var(--main-color);
      border: 1px solid var(--main-color)
    }

    &--left {
      border-bottom-left-radius: 0 !important;
    }

    &--right {
      border-bottom-right-radius: 0 !important;
    }

    &--small {
      padding: calc($toolbar-button-padding-small / 2) $toolbar-button-padding-small;
      font-size: calc(var(--font-size-base) - 2px) !important;

      .toolbar__button__icon svg {
        height: 17px;
      }
    }

    &__icon {
      display: inline-flex;

      svg {
        width: auto;
        height: 20px;
      }
    }
  }
}
