.integration {
  &__notices {
    width: 60%;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);

    &--fullwidth {
      width: 100%;
      max-width: unset;
    }
  
    &__select-language {
      display: flex;
      align-items: center;
      color: var(--color-midnight-light);
  
      &__selected {
        font-weight: $font-weight-bold;
        margin: 0 var(--spacing-xs) 0 var(--spacing-sm);
      }
    }

    &__image {
      margin: 0 auto;

      & > img {
        max-width: 100%;
        border: 1px solid var(--color-grey);
        box-shadow: $box-shadow;
      }
    }

    &__content-separator i {
        font-size: $font-size-48;
    }
  }

  &__cross-sales {
    width: 40%;
    max-width: 40rem;
  }

  @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
    &__notices,
    &__cross-sales {
      width: 100%;
      max-width: unset;
      margin: 0;
    }
  }

  @media #{$screen-s}, #{$screen-m} {
    &__notices {
      p {
        margin: 10px 0;
      }
    }
  }
}

$step-number-size: 2rem;

.installation-steps {
    gap: 0;

    .installation-step {
        display: flex;
        flex-direction: column;
    
        &__title {
            & > div {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: $step-number-size;
                height: $step-number-size;
                border: 1px solid var(--color-grey);
                background-color: var(--color-grey-light);
                border-radius: var(--border-radius-xs);
                font-size: $font-size-18;
                font-weight: $font-weight-bold;
                margin-right: var(--spacing-lg);
            }

            & > * {
                color: var(--color-ocean);
            }
        }
    
        &__content {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-sm);
            border-left: 1px solid var(--color-grey);
            margin-left: calc($step-number-size / 2);
            padding: var(--spacing-md) 0 var(--spacing-xl) calc($step-number-size / 2 + var(--spacing-lg));

            & > p {
                margin: 0;
                line-height: 1.8;
            }
        }

        &:last-child > .installation-step__content {
            padding-bottom: var(--spacing-sm);
            border-image: linear-gradient(to bottom, var(--color-grey) 85%, transparent) 1;
        }
    }
}
