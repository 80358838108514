.module-download {
  &__icon-download {
    font-size: $font-size-30;
  }

  &:hover,
  &:active {
    box-shadow: $box-shadow;

    .h3,
    .module-download__icon-download {
      color: var(--color-green);
    }
  }
}
