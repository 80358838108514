.modal-create-config {
  &__mode {
    @media #{$screen-xl} {
      max-width: 25rem;
      width: 100%;
      height: 100%;

      &:not(:last-child) {
        border-right: 1px solid var(--color-grey);
        padding-right: var(--spacing-lg);
      }
    }

    @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
      &:not(:last-child) {
        border-bottom: 1px solid var(--color-grey);
        padding-bottom: var(--spacing-lg);
      }
    }

    &__content {
      color: var(--color-midnight-light);

      &__title {
        font-size: $font-size-20;
        font-weight: $font-weight-bold;
        color: var(--color-midnight);
      }

      &__steps {
        &__item {
          display: inline-flex;
          gap: var(--spacing-sm);

          &__icon {
            color: var(--color-green);
            font-size: $font-size-20;
          }
        }
      }

      &__alert {
        @media #{$screen-s} {
          display: none;
        }
      }
    }

    &__footer {
      width: 100%;

      & > img {
        width: 200px;

        @media #{$screen-s} {
          width: 135px;
        }
      }
    }
  }
}
