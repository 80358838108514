$score-circle-size: 13rem;
$score-circle-track-width: 1.17rem;

.score-circle-container {
  .score-circle {
    *, *:before, *:after {
      box-sizing: content-box;
    }

    position: relative;
    font-size: $score-circle-size;
    width: $score-circle-size;
    height: $score-circle-size;
    border-radius: 50%;
    background-color: var(--color-grey);

    &:after {
      position: absolute;
      top: $score-circle-track-width;
      left: $score-circle-track-width;
      display: block;
      content: " ";
      border-radius: 50%;
      width: $score-circle-size - (2 * $score-circle-track-width);
      height: $score-circle-size - (2 * $score-circle-track-width);
      background: var(--color-white);
    }

    .score-circle-value {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;
      font-size: $font-size-56;
      font-weight: $font-weight-semi-bold;
      color: var(--color-midnight-light);

      .score-circle-label {
        font-size: $font-size-20;
        line-height: 2;
      }

      .score-circle-number {
        font-size: $font-size-40;
        line-height: 1;
      }
    }

    .score-circle-track {
      position: absolute;
      width: $score-circle-size;
      height: $score-circle-size;
      clip: rect(0rem, $score-circle-size, $score-circle-size, 6.5rem);

      .score-circle-trackbar {
        position: absolute;
        border: $score-circle-track-width solid var(--color-green);
        width: $score-circle-size - (2 * $score-circle-track-width);
        height: $score-circle-size - (2 * $score-circle-track-width);
        clip: rect(0rem, 6.5rem, $score-circle-size, 0rem);
        border-radius: 50%;
        transform: rotate(0deg);
      }

      .score-circle-run {
        @extend .score-circle-trackbar;
      }
    }

    @for $j from 51 through 100 {
      &.p#{$j} {
        .score-circle-track {
          clip: rect(auto, auto, auto, auto);

          .score-circle-run:after {
            transform: rotate(180deg);
          }

          .score-circle-fixed {
            @extend .score-circle-trackbar;
            transform: rotate(180deg);
          }
        }
      }
    }

    @for $j from 1 through 100 {
      &.p#{$j} {
        .score-circle-track {
          .score-circle-run {
            transform: rotate((calc(360/100)*$j) + deg);
          }
        }
      }
    }
  }

  .score-circle-title {
    margin: 1rem 0;
    width: 100%;
    font-size: $font-size-12;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-midnight-lighter);
    text-align: center;
  }
}
