.theme-position {
  &__preview {
    display: flex;
    justify-content: center;
    padding: var(--spacing-sm);
    background-color: var(--color-grey);
    border-radius: var(--border-radius-md);
    width: 50%;

    img {
      width: 100%;
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
  }
}

.theme-colors {
  & &__mode {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);

    &__icon {
      padding: .4rem;
      border-radius: 50%;
      border: 1px solid var(--color-grey);
      background-color: var(--color-white);
      color: var(--color-midnight);
      font-size: $font-size-14;

      &--dark {
        background-color: var(--color-midnight);
        color: var(--color-grey);
      }
    }
  }
}

.theme-images {
  & &__mode {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-top: var(--spacing-xs);
    margin-left: var(--spacing-xs);

    &__icon {
      position: absolute;
      top: calc(-1 * var(--spacing-xs));
      left: calc(-1 * var(--spacing-xs));
      z-index: 1;
      padding: .4rem;
      border-radius: 50%;
      border: 1px solid var(--color-grey);
      background-color: var(--color-white);
      color: var(--color-midnight);
      font-size: $font-size-14;

      &--dark {
        background-color: var(--color-midnight);
        color: var(--color-grey);
      }
    }
  }
}
