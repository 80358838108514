.configuration-mode {
  padding: var(--spacing-sm);
  height: 100%;

  &__content {
    color: var(--color-midnight-light);

    &__title {
      font-size: $font-size-26;
      font-weight: $font-weight-bold;
      color: var(--color-midnight);
      margin-top: var(--spacing-xs);
    }

    &__steps {
      &__item {
        display: inline-flex;
        gap: var(--spacing-sm);

        &__icon {
          color: var(--color-green);
          font-size: $font-size-20;
        }
      }
    }
  }

  &__footer {
    width: 100%;

    & > img {
      width: 200px;
    }
  }
}