.cross-sales {
  width: 100%;
  max-width: 40rem;
  height: fit-content;
  background-color: var(--color-grey-light);

  &__title {
    color: var(--color-midnight);
  }

  &__accordion-content {
    color: var(--color-midnight-lighter);
  }
}

.cross-sales-modal {
  text-align: center;

  &__content {
    & > div {
      text-align: left;
      width: 50%;
  
      & > svg {
        width: 80%;
        height: fit-content;
      }
    }
  
    @media #{$screen-s}, #{$screen-m} {
      & > div {
        width: 100%;
        &:first-child {
          display: none;
        }
      }
    }
  }
}
