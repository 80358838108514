.preview {
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-xs);
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-xs);
  }

  &__toggle {
    display: flex;
    flex-direction: row;
    margin-bottom: var(--spacing-xs);
    border: 1px solid var(--color-grey);
    border-radius: var(--border-radius-md);
    overflow: hidden;
    user-select: none;

    &__button {
      display: flex;
      padding: var(--spacing-xs) var(--spacing-sm);
      background-color: var(--color-white);
      color: var(--color-midnight);

      &--active {
        border-color: var(--color-ocean-light);
        background-color: var(--color-ocean-lighter);
        color: var(--color-ocean);
      }
    }

    & i:hover {
      color: inherit;
    }
  }

  &__container {
    width: 100%;
    margin: 0 auto;
    border: 1px solid var(--color-grey);
    border-radius: var(--border-radius-md);
    overflow: hidden;

    &__header {
      display: flex;
      flex-direction: row;
      gap: var(--spacing-xs);
      align-items: center;
      padding: var(--spacing-sm);
      background: var(--color-grey-light);

      i {
        font-size: $font-size-14;
      }
    }

    &__iframe {
      position: relative;
      height: auto;
      padding: 0 0 55rem 0;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        border: 0;
        width: 100%;
        height: 100%;
      }

      &__no-preview {
        display: none;
      }
    }

    &--mobile {
      .preview__container__iframe {
        height: auto;
        padding: 0 0 calc(55rem - (var(--spacing-sm) * 2)) 0;
        margin: var(--spacing-sm) auto;
        max-width: 25rem;
        border: 1px solid var(--color-grey);
        box-shadow: $box-shadow;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .preview__container {
    &:not(.preview__container--mobile) {
      .preview__container__iframe iframe {
        width: 112%;
        height: 112%;
        transform: scale(0.9);
        transform-origin: 0 0;
      }
    }
  }
}

@media #{$screen-s}, #{$screen-m} {
  .preview__container {
    &:not(.preview__container--mobile) {
      .preview__container__iframe iframe {
        width: 143%;
        height: 143%;
        transform: scale(0.7);
        transform-origin: 0 0;
      }
    }
  }
}

@media #{$screen-s} {
  .preview__container__iframe {
    display: none;
  }

  .preview__container__iframe__no-preview {
    display: block;
    padding: var(--spacing-xl) var(--spacing-md);
  }
}
