.home {
  .changelog {
    display: flex;
    flex-direction: column;

    .changelog-item {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid var(--color-grey);
      }

      .version-num {
        margin-bottom: .75rem;
        font-size: $font-size-16;
        color: var(--color-midnight-light);
      }

      .version-text {
        margin-bottom: .75rem;
        font-size: $font-size-14;
        color: var(--color-midnight-light);
      }

      .version-num {
        font-weight: $font-weight-bold;
      }

      .version-date {
        font-size: $font-size-12;
        color: var(--color-midnight-lighter);
      }
    }
  }
}
