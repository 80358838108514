.home {
  $consent-rates-item-count: 3;
  $consent-rates-height: 24rem;
  $consent-rates-item-height: calc($consent-rates-height / $consent-rates-item-count);

  .consent-rates {
    display: flex;
    gap: var(--spacing-lg);
    height: $consent-rates-height;
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    overflow: hidden;

    .consent-sites {
      width: 25%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .site-items {
        border-right: 2px solid var(--color-cyan);

        .site-item {
          display: flex;
          align-items: center;
          background-color: var(--color-grey-light);
          height: $consent-rates-item-height;
          position: relative;
          cursor: pointer;

          .site-infos {
            display: flex;
            flex-direction: column;
            margin: var(--spacing-lg);
            gap: var(--spacing-sm);
            width: 100%;
            color: var(--color-midnight-lighter);
            overflow: hidden;

            .site-name {
              font-size: $font-size-14;
              font-weight: $font-weight-semi-bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              > span:hover {
                text-decoration: underline;
              }
            }

            .site-rate {
              font-size: $font-size-20;
              font-weight: $font-weight-bold;

              &__small-rate {
                font-size: $font-size-14;
                font-weight: $font-weight-light;
              }
            }
          }

          .site-arrow {
            display: none;
            position: absolute;
            right: -2.125rem;
            width: 0;
            height: 0;
            border-top: calc($consent-rates-item-height / 2) solid transparent;
            border-bottom: calc($consent-rates-item-height / 2) solid transparent;
            border-left: calc($consent-rates-item-height / 4) solid var(--color-cyan);
          }

          &.active,
          &:hover {
            background-color: var(--color-cyan);

            .site-infos {
              color: var(--color-white);
            }

            .site-arrow {
              display: block;
            }
          }
        }
      }
    }

    .consent-charts {
      display: flex;
      flex-direction: column;
      padding: var(--spacing-md);
      width: 75%;

      .chart-title {
        text-align: right;
        font-size: $font-size-12;
        color: var(--color-midnight-lighter);
      }

      .chart-item {
        display: none;
        position: relative;
        width: 100%;
        height: 100%;

        &.active {
          display: flex;
        }

        canvas {
          width: 100% !important;
          height: calc($consent-rates-height - var(--spacing-xl)) !important;
        }
      }
    }
  }

  @media #{$screen-s}, #{$screen-m} {
    .consent-rates {
      flex-direction: column;
      gap: 0;
      padding: 0 !important;
      height: unset;

      .consent-sites {
        width: 100%;
        height: auto;

        .site-items {
          display: flex;
          justify-content: space-between;
          border: none;
          border-bottom: 2px solid var(--color-cyan);
          flex-wrap: wrap;

          .site-item {
            width: calc(100% / $consent-rates-item-count);

            .site-infos {
              text-align: center;

              .site-name {
                font-size: $font-size-14;
              }
            }

            .site-arrow {
              display: none !important;
            }
          }
        }
      }

      .consent-charts {
        width: 100%;
      }
    }
  }
}
