.google-errors {
  .faq-accordion {
    padding: var(--spacing-md) !important;
    background-color: var(--color-grey-light);
  
    ul {
      padding: var(--spacing-sm) 0;
      margin-left: var(--spacing-lg);
  
      li {
        list-style-type: disc;
        line-height: 1.5;
      }
    }
  }
  
  .iframe-container {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 60vh;
    border-radius: var(--border-radius-md);
    background-color: var(--color-grey-light);
    overflow: hidden;
  
    iframe {
      position: absolute !important;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: auto;
      border: 0;
    }
  }
  
  .flow-chart {
    height: 60vh;
    padding: 0;
    background: url("../img/logo-sirdata-2021-opacity50.svg") center/80% no-repeat;
    background-color: var(--color-grey-light);
    border: 1px solid var(--color-grey);
    border-radius: var(--border-radius-md);
    overflow: hidden;
  }
}
