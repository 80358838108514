.add-ons {
  &__description {
    color: var(--color-midnight-lighter);
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__logo {
      width: 20%;
      display: flex;
      justify-content: center;

      img {
        max-width: 8rem;
        width: 80%;
      }
    }

    &__content {
      width: 100%;
      display: flex;
      justify-content: left;
      flex-direction: column;
      padding: 0 1rem;
      gap: var(--spacing-sm);

      &__toggle {
        label {
          margin: 0 .75rem;
          font-size: $font-size-16;
          font-weight: $font-weight-bold;
        }
      }

      &__title {
        font-size: $font-size-16;
        font-weight: $font-weight-bold;
      }

      &__toggle-link {
        font-weight: $font-weight-bold;
      }

      &__details {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-xs);
      }

      &__note {
        width: 100%;
        display: flex;
        justify-content: left;
        flex-direction: column;
        padding: .5rem 1rem;
        font-weight: $font-weight-light;
        border-left: .6rem solid var(--color-green);
        border-radius: var(--border-radius-sm);
        background-color: var(--color-grey-light);
        color: var(--color-midnight-light);

        label {
          font-weight: $font-weight-bold;
          text-transform: uppercase;
        }

        span {
          ul {
            padding-inline-start: 1.5rem;

            li::marker {
              content: '•  ';
            }
          }
        }

      }

      &__products {
        width: 100%;
        display: flex;
        justify-content: left;
        flex-direction: column;
        margin-bottom: var(--spacing-xs);
        gap: var(--spacing-sm);

        &__logos {
          display: flex;
          justify-content: left;
          flex-direction: row;
          flex-wrap: wrap;
          gap: var(--spacing-sm);

          img {
            height: 3rem;
          }
        }
      }
    }

    @media #{$screen-s}, #{$screen-m} {
      align-items: center;
      flex-direction: column;

      &__logo {
        margin-bottom: .75rem;
      }
    }
  }
}
