.configuration-flash {
  color: var(--color-midnight-light);

  &__title {
    display: flex;
    align-self: center;
    text-align: center;
    font-weight: $font-weight-bold;
    font-size: $font-size-26;
    color: var(--color-green);
    max-width: 29rem;
  }

  &__advanced-config {
    margin-bottom: var(--spacing-xs);
  }

  &__cmp-position {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: inherit;
    align-items: flex-start;

    &__preview {
      display: flex;
      justify-content: center;
      margin-right: var(--spacing-sm);
      padding: var(--spacing-sm);
      background-color: var(--color-grey);
      border-radius: var(--border-radius-md);
      width: 100%;
    }

    &__choice {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-left: var(--spacing-sm);

      &__item {
        width: 50%;
        margin: var(--spacing-xs) 0;
      }
    }
  }

  &__preview {
    width: 100%;

    .preview__link {
      display: inline-flex;
      margin-top: var(--spacing-sm);
    }
  }

  &__preview-block {
    margin-bottom: var(--spacing-sm);
  }
}

@media #{$screen-s}, #{$screen-m} {
  .configuration-flash {
    &__cmp-position {
      flex-direction: column;

      &__preview {
        margin-bottom: var(--spacing-xs);
      }
    }
  }
}
