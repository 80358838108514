.image-manager {
  width: 100%;
  display: flex;
  gap: var(--spacing-sm);

  &__preview {
    width: $image-size-thumbnail;
    height: $image-size-thumbnail;
    border: 1px solid var(--color-grey);
    border-radius: var(--border-radius-md);
    background-color: var(--color-white);
    padding: var(--spacing-xs);
    cursor: pointer;

    &__content {
      position: relative;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      background-color: var(--color-grey-light);
      border-radius: var(--border-radius-sm);

      &__icon {
        font-size: $font-size-36;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        user-select: none;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xs);
    margin-top: var(--spacing-xs);

    &--minified {
      margin-top: 0;

      & i {
        padding: .2rem;
        font-size: $font-size-18;
        border: 1px solid var(--color-grey);
        border-radius: var(--border-radius-sm);
      }
    }
  }
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: var(--spacing-xs);

  &__image {
    width: $image-size-thumbnail;
    height: $image-size-thumbnail;
    padding: var(--spacing-xs);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-grey);
    position: relative;
    cursor: pointer;

    &__content {
      position: relative;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      border-radius: var(--border-radius-sm);

      &--cancel, &--loading {
        background-color: var(--color-grey);

        & > i {
          font-size: $font-size-48;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:hover {
      border-color: var(--color-grey-dark);
      box-shadow: inset var(--color-grey-dark) 0 0 0 2px;
    }

    &--active, &--active:hover {
      border-color: var(--color-green);
      box-shadow: inset var(--color-green) 0 0 0 2px;
    }

    &__clear, &__edit {
      position: absolute;
      bottom: -0.5rem;
      background-color: var(--color-white);
      border-radius: 50%;
    }

    &__clear {
      left: 50%;
    }

    &__edit {
      right: 50%;
    }
  }
}

.image-selection-box {
  width: 100%;
  height: 21rem;
  overflow: auto;

  & input[type=file] {
    display: none;
  }
}
