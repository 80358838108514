.login-left {
  &-content {
    .login-title {
      margin-top: 7vh;
      font-size: $font-size-40;
      font-weight: $font-weight-bold;
      color: var(--color-grey);
      text-align: center;
    }
  }
}

.login-left,
.login-bottom {
  .login-items {
    &:not(:first-child) {
      margin-top: 2vh;
    }

    &__certifications {
      margin-top: var(--spacing-lg);
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      gap: var(--spacing-sm);

      img {
        height: 6rem;
      }
    }
  }
}

.login-right {
  & h1 {
    font-size: $font-size-24;
  }

  &__content {
    margin-bottom: var(--spacing-xl);

    &__hero-title {
      padding-top: calc(var(--spacing-lg) * 3);
      padding-bottom: 0;

      span {
        width: 90%;
      }
    }

    &__separator {
      text-align: center;

      & i {
        font-size: $font-size-48;
      }
    }

    &__pricing {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-xl);
      padding: var(--spacing-lg);

      &__item {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-md);
        align-items: center;
        width: 25rem;
        transition: transform .15s ease-in-out;

        &:hover {
          transform: scale(1.05);
          box-shadow: 0 0 16px 0 #00000016;
          border-color: transparent;
        }

        &__tax {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: var(--spacing-xs);
          font-size: $font-size-20;
          font-weight: $font-weight-bold;
          color: var(--color-midnight-lighter);

          &__price {
            font-size: $font-size-70;
            font-weight: $font-weight-semi-bold;
            color: var(--color-green);
          }
        }

        &__details {
          display: flex;
          gap: var(--spacing-xs);

          i {
            color: var(--color-green);
            font-size: $font-size-20;
          }
        }
      }

      &__buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: calc(var(--spacing-md) * 2);

        button {
          white-space: break-spaces;
        }
      }
    }

    &__see-more {
      width: fit-content;
      margin: 0 auto;
    }
  }
}

.login-button-container {
  position: absolute;
  top: var(--spacing-md);
  right: var(--spacing-md);
  z-index: 1;
}

/* RESPONSIVE */
@media #{$screen-l} {
  .login-right {
    &__content {
      &__pricing {
        &__item {
          width: 90%;
        }
      }
    }
  }
}

@media #{$screen-s}, #{$screen-m} {
  .login-left {
    &-content {
      .login-title {
        font-size: $font-size-30;
        margin: var(--spacing-lg) 0 0 !important;
      }
    }

    .main-logo {
      height: 5rem;
    }
  }

  .login-right {
    &__content {
      &__hero-title {
        min-height: 10rem;
        padding: var(--spacing-xl) var(--spacing-md);
        padding-bottom: 0;
        margin-bottom: 0;
      }

      &__separator {
        display: none;
      }

      &__pricing {
        padding: var(--spacing-md);
        gap: var(--spacing-lg);

        &__item {
          width: 90%;
        }

        &__buttons {
          gap: var(--spacing-md);
        }
      }
    }
  }
}

@media #{$screen-s} {
  .login-left {
    &-content {
      .login-title {
        font-size: $font-size-26;
      }
    }

    .main-logo {
      height: 3rem;
      text-align: left;
    }
  }
}
